import React, { useEffect } from "react"
import MediaOverlay from "../mediaOverlay"
import useModal from "../useModal"
import SwiperDefault from "../swiperDefault"
import { SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default function ThumbleisteSwiper(props) {
  const { isShowing, activeImg, toggle } = useModal()
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState(typeof window !== "undefined" ? window.innerWidth : 0)
  useEffect(() => {
    /* thumbs nacheinander zeigen*/
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    gsap.set(".kachel", { opacity: 0 })

    ScrollTrigger.batch(".kachel", {
      start: "center bottom",
      onEnter: (elements, triggers) => {
        gsap.to(elements, { opacity: 1, stagger: 0.15, overwrite: true })
      },
    })
  }, [])

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  }

  const mobileView = props.picdata.edges.map(({ node }, i) => {
    return (
      <div key={i} className="p-3">
        <div
          id={i + 1} // der Index ist zwar 0 aber der Swiper-Index ist 1 daher immer +1
          data-nodeid={node.id}
          className="kachel"
          onClick={toggle}
        >
          <GatsbyImage
            // image={node.childImageSharp.gatsbyImageData}
            image={node.childImageSharp.picscropped}
            alt=""
            placeholder="blurred"
            objectFit="cover"
            objectPosition="left center"
          />
        </div>
      </div>
    )
  })

  const desktopView = props.picdata.edges.map(({ node }, i) => {
    return (
      <SwiperSlide key={i}>
        <div
          id={i + 1} // der Index ist zwar 0 aber der Swiper-Index ist 1 daher immer +1
          data-nodeid={node.id}
          className="kachel"
          onClick={toggle}
        >
          <GatsbyImage
            // image={node.childImageSharp.gatsbyImageData}
            image={node.childImageSharp.picscropped}
            alt=""
            placeholder="blurred"
            objectFit="cover"
            objectPosition="left center"
          />
        </div>
      </SwiperSlide>
    )
  })

  const handleBreakpointChange = swiper => {
    setCurrentBreakpoint(swiper.currentBreakpoint)
  }

  return (
    <div id="thumbleiste" className={`py-5`}>
      <SwiperDefault
        onBreakpoint={handleBreakpointChange}
        spaceBetween={50}
        breakpoints={breakpoints}
      >
        {currentBreakpoint < 576 ? mobileView : desktopView}
      </SwiperDefault>

      {/* MediaOverlay */}
      <MediaOverlay
        mediaData={props.picdata}
        activeImg={activeImg}
        isShowing={isShowing}
        hide={toggle}
        bgCol={props.bgCol}
      />
    </div>
  )
}
