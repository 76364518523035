import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import Specs from "../../../components/specs"
import myLocalVid from '../../../images/projekte/smart-home-konfigurator/bje-shc.mp4'
import * as projekteStyles from "../projekte.module.css"
import YtVideo from "../../../components/ytvideo"


const BuschJaegerSmartHomeKonfigurator = function (props, ref) {

    const data = useStaticQuery(graphql`
        query BuschJaegerSmartHomeKonfiguratorQuery {
            heropic: file(relativePath: { eq: "images/projekte/smart-home-konfigurator/landing/smart-home-og.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/smart-home-konfigurator/landing/smart-home-og.jpeg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/smart-home-konfigurator/thumbleiste"}}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Konfigurator", "https://smart-home-configurator.my.busch-jaeger.de/"] },
        { title: "Leistungen", objects: ["konzept", "workshop", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Busch-Jaeger Smart Home-Konfigurator"
                description="Q:marketing realisiert Smart Home Konfigurator für Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">
                <div className="row py-5 py-md-0">
                <div className="col-12 col-md-4">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Smart Home Konfigurator</h1>
                        <p className="introtext">Der Konfigurator hilft Profis und Verbrauchern gleichermaßen ein individuelles Smarter Home Projekt zu planen und zu realisieren. Schritt für Schritt zur individuellen und bedarfgerechten Smart Home Planung.</p>
                        <AnchorLink to="/projekte/konfiguratoren/busch-jaeger-smart-home-konfigurator/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>   
                </div>
            </BigHeaderVideo>
                    
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#ff00ff" />    

            {/* Video */}
            <ListenModule headline="Schritt für Schritt">
                <div className="row">
                    <YtVideo videoId="ILdz2cNhmyU"/>                
                </div>
            </ListenModule>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Interaktive Konfiguration eines Smart Home Systems für Endkunden und Elektroinstallateure, Anbindung an Großhandelsplattformen.",
                        "Schritt für Schritt zur individuellen Smart Home Planung.",
                        "Jalousie, Licht, Heizung, Klima und Türkommunikation: Planung aller Produkte entlang der Busch-Jaeger Sortimentsebene.",
                        "Kalkulation für Neubau- und Renovationsvorhaben.",
                        "Anbindung an Großhandelsshops per ELBRIDGE-Schnittstelle.",
                    ]}
                />
            </ListenModule>
           
            <Specs data={specsData} />
                   

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

            {/* navigation */}            
            <Navigation location={props.location} />            

        </Layout>
    )
}

export default BuschJaegerSmartHomeKonfigurator