import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import BackgroundVideo from "../../backgroundVideo"

import * as bhStyles from "../bigheader.module.css"

const BigHeaderVideo = props => {
  const tl = useRef()
  const headerRef = useRef()
  const mediaRef = useRef()
  const textRef = useRef()

  const backgroundStyle = {
    backgroundImage: `${props.bgImage ? `url(${props.bgImage})` : "none"}`,
    backgroundColor: `${props.bgCol ? `${props.bgCol}` : "none"}`,
  }

  var thismode = props.mode

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.core.globals("ScrollTrigger", ScrollTrigger)

    // bei schmalem viewport video und text untereinander anordnen -> allatonce triggern
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )
    //const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    if (vw < 576) {
      //console.log("downgrade auf allatoncee" + vw)
      thismode = "allatonce"
    }

    // starte mit grossem bild
    if (thismode === "videoOnly") {
      var tl2 = gsap
        .timeline({
          scrollTrigger: {
            trigger: headerRef.current,
            pin: true,
            scrub: 1,
            start: "top top",
            markers: false,
            pinSpacing: false,
            snap: {
              snapTo: "labels", // snap to the closest label in the timeline
              duration: { min: 1, max: 2 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 0.5, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power3", // the ease of the snap animation ("power3" by default)
            },
          },
        })
        .addLabel("start")
        .fromTo(
          mediaRef.current,
          { filter: "brightness(1) grayscale(0%)" },
          { filter: "brightness(0.7) grayscale(100%)" }
        )
        .addLabel("end")
    }

    // starte mit grossem bild
    if (thismode === "mediaFirst") {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            // id: "bigheaderScrollTrigger",
            trigger: "#bigheaderWrap",
            pin: true, // pin the trigger element while active
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "bottom 40%", // end after scrolling 500px beyond the start
            scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: false,
            snap: {
              snapTo: "labels", // snap to the closest label in the timeline
              duration: { min: 0.2, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 1, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
            },
          },
        })
        .addLabel("move")
        .from(textRef.current, { y: "+=50", opacity: "0" })
        .to(
          mediaRef.current,
          {
            scale: "0.8",
            opacity: "0.3",
            filter: "grayscale(1)",
            transformOrigin: "right bottom",
          },
          "<"
        )
        .addLabel("end")
    }

    //starte mit dem text
    if (thismode === "textFirst") {
      gsap.set(mediaRef.current, {
        scale: "0.8",
        opacity: "1",
        filter: "saturate(0%)",
        transformOrigin: "right bottom",
      })

      tl.current = gsap
        .timeline({
          scrollTrigger: {
            // id: "bigheaderScrollTrigger",
            trigger: "#bigheaderWrap",
            pin: true, // pin the trigger element while active
            start: "top top", // when the top of the trigger hits the top of the viewport
            end: "bottom 20%", // end after scrolling 500px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            markers: false,
            snap: {
              snapTo: "labels", // snap to the closest label in the timeline
              duration: { min: 0.25, max: 1 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
              delay: 0.25, // wait 0.2 seconds from the last scroll event before doing the snapping
              ease: "power3.inOut", // the ease of the snap animation ("power3" by default)
            },
          },
        })
        .addLabel("move")
        .to(textRef.current, {
          y: "-=50",
          opacity: "0",
        })
        .to(
          mediaRef.current,
          {
            scale: "1",
            opacity: "1",
            filter: "saturate(100%)",
            transformOrigin: "right bottom",
          },
          "<"
        )
        .addLabel("end")
    }

    //text und video zusammen
    if (thismode === "allatonce") {
      gsap.set(textRef.current, { y: "+=50", opacity: "0" })

      tl.current = gsap
        .timeline({})
        .addLabel("move")
        .to(textRef.current, {
          y: "-=50",
          opacity: "1",
          duration: "1",
        })
        .addLabel("end")
    }
  }, [])

  return (
    <div
      id="bigheaderWrap"
      ref={headerRef}
      className={bhStyles.bigheaderWrap}
      style={backgroundStyle}
    >
      <BackgroundVideo
        ref={mediaRef}
        bgVideo={props.bgVideo}
        bgCol={props.bgCol}
        bgImage={props.posterImage}
        bgMode={props.videoMode}
      />

      {/* TEXT */}
      {thismode != "videoOnly" && (
        <>
          <div
            ref={textRef}
            className={`container-fluid fullorhalfpage ${bhStyles.textDivWrapper}`}
          >
            {props.children}
          </div>
        </>
      )}
    </div>
  )
}

// Specifies the default values for props:
BigHeaderVideo.defaultProps = {
  mode: "",
  bgVideo: "EeX50qY3N8M",
  videoMode: "",
}

export default BigHeaderVideo
