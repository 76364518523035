import React, { useRef, useState, useEffect } from "react"
import YouTube from "react-youtube"
import { GatsbyImage } from "gatsby-plugin-image"

import * as bgvStyles from "./backgroundVideo.module.css"

const BackgroundVideo = React.forwardRef((props, ref) => {
  const posterImgRef = useRef()

  const [enableYoutube, setEnableYoutube] = useState(true)

  useEffect(() => {
    setEnableYoutube(localStorage.getItem('enable-youtube'))
  }, [])

  const videoOptions = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // https://developers.google.com/youtube/iframe_api_reference#Events
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      playsinline: 1,
      mute: 1,
    },
  }
  
  const _onReady = event => {
    // access to player in all event handlers via event.target
    // event.target.mute()
    //event.target.playVideo()
    event.target.playVideo()
    posterImgRef.current.classList.add("d-none")
  }

  const _onEnd = event => {
    event.target.playVideo()
  }

  return (
    <div ref={ref} className={`${bgvStyles.videoBackground} fullorhalfpage`}>
      <div className={bgvStyles.videoForeground}>
        {(props.bgMode === "local" || !enableYoutube) && (
          <video className="video-iframe" autoPlay playsInline muted loop preload>
            <source
              src={props.bgVideo}
              type='video/mp4'
            />
          </video>
        )}
        {(props.bgMode !== "local" && enableYoutube) && (
          <YouTube
            /* never forget videoId="Z6FPJOgfCkc" */
            videoId={props.bgVideo}
            opts={videoOptions}
            className="video-iframe"
            onReady={_onReady}
            onEnd={_onEnd}
          />
        )}
      </div>
      {/* htgr bild, verschwindet wenn video startet*/}
      <div ref={posterImgRef}>
        {props.bgImage && (
          // <Img fluid={props.bgImage} alt="poster image" />
          <GatsbyImage
            image={props.bgImage}
            alt="HALLO"
            placeholder="blurred"
            objectFit="contain"
            objectPosition="center center"
          />
        )}
      </div>
    </div>
  )
})

// Specifies the default values for props:
BackgroundVideo.defaultProps = {
  bgVideo: "EeX50qY3N8M",
  videoMode: "",
}

export default BackgroundVideo
