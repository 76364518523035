import React, { forwardRef, useState, useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import gsap from "gsap"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import * as MediaOverlayStyles from "./mediaOverlay.module.css"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "gatsby-image"

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
])

//https://source.unsplash.com/random/800x600

const Modal = ({ mediaData, isShowing, activeImg, hide, bgCol }) => {
  const [swiper, setSwiper] = useState(null)
  const tl = useRef()

  const customStyles = {
    backgroundColor: bgCol,
  }

  useEffect(() => {
    if (swiper != null) {
      swiper.slideTo(activeImg)
    }

    tl.current = gsap
      .timeline()
      .addLabel("start")
      //(document.documentElement.clientWidth/100)*75
      .fromTo(
        ".mediaOverlay",
        { opacity: 0, yPercent: 5, scale: 0.95 },
        {
          opacity: 1,
          yPercent: 0,
          scale: 1,
          duration: 1,
          ease: "expo.out",
          transformOrigin: "center center",
        }
      )
  }, [swiper])

  return isShowing
    ? ReactDOM.createPortal(
        <div
          className={`${MediaOverlayStyles.mediaOverlayWrapper} mediaOverlay`}
          style={customStyles}
        >
          <div className={`${MediaOverlayStyles.navBar} container-fluid`}>
            <div className="row vcenterRowContent p-2">
              <div className="col">{/* <span>Projekte</span> */}</div>
              <div
                className="col text-right"
                onClick={hide}
              >
                <StaticImage
                  alt="Titelbild"
                  style={{ cursor: "pointer" }}
                  src="../../images/icon-close.svg"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>

          <Swiper
            /*  effect="cube"
                  cubeEffect={{ shadow: true }, { shadowOffset: 20 }, { shadowScale: 0.94 }} */
            effect="slide"
            loop={true}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            grabCursor={true}
            pagination={{ clickable: true, type: "bullets" }}
            keyboard={{
              enabled: true,
              onlyInViewport: true,
            }}
            mousewheel={{ invert: false, forceToAxis: true }}
            onSwiper={setSwiper}
          >
            {mediaData.edges.map(({ node }, i) => (
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={node.childImageSharp.pics}
                  alt=""
                  placeholder="blurred"
                  objectFit="cover"
                  objectPosition="center center"
                  style={{
                    width: "100vw",
                    height: "100vh",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>,
        document.body
      )
    : null
}

// Specifies the default values for props:
Modal.defaultProps = {
  bgCol: "#ffffff",
}

export default Modal
